<nav class="mw3-sidenav w3-red w3-animate-left w3-collapse w3-top w3-large " id="banner">

  <div class="w3-container w3-padding-16">
    <div style="text-align: center;">
      <input (keyup)="onKeyUp($event)" id="searchRooms" class="w3-input w3-border" type="text"
        placeholder="ค้นหาห้อง...">
    </div>
  </div>
  <ul id="menuViewer">
    <li mat-raised-button matTooltip='{{data.title}}' matTooltipPosition="right" matTooltipClass="tooltip-class"
      *ngFor="let data of room.rooms ;let i = index" (click)='this.getData(data.id)' class="listrooms">
      <a>

        <div class="dotdot">{{data.title}} </div>
        <font size="1">
          {{this.room.startTime[i]}} - {{this.room.endTime[i]}}
        </font>
        <div [style.color]="room.statusColor[i]" style="font-size: 13px; ">
          {{this.room.status[i]}}
          <br *ngIf="room.status[i] != ''">
          {{this.room.timeStatus[i]}}
        </div>
      </a>
    </li>
  </ul>
  <ul *ngIf="noConnection" id="menuViewer">
    <h1>
      <b id="notFound">อุปส์!</b>
    </h1>
    <p>เกิดข้อผิดพลาดระหว่างการเชื่อมต่อ โปรดลองใหม่อีกครั้ง</p>
    <button id="reconnectRooms">ลองใหม่</button>
  </ul>
  <ul *ngIf="room.rooms.length == 0" id="menuViewer" class="error">
    <!-- Not found Rooms -->
    <div id="notFound">ไม่พบข้อมูล</div>
    <button id="reconnectRooms" (click)="getRoom()">ลองใหม่</button>
  </ul>


  <div style="background-color: black"></div>
</nav>

<router-outlet></router-outlet>