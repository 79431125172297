<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="float-left display-3 mr-4"><i class="fa fa-exclamation-triangle"></i></h1>
      </div>
      <div class="col-md-12">
        <div class="clearfix">
          <h1 class="pt-3">ไม่สามารถใช้งานได้</h1>
         
        </div>
      </div>
      <div class="col-md-12">
        <p class="text-muted">คุณไม่มีสิทธิ์ในการใช้งานระบบ</p>
            <button type="button" class="btn btn-primary" (click)="logout()">
              ออกจากระบบ
            </button>&nbsp;
            <button type="button" class="btn" (click)="viewer()">
              ย้อนกลับ
            </button>
      </div>
    </div>
  </div>
</div>
