import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class AuthGuardService {

  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check user login
    if (localStorage.getItem('auth') != null) {
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/view']);
    return false;
  }
}

@Injectable()
export class MobileGuard {

  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    if (!(/Android|iPhone/i.test(window.navigator.userAgent))) {
      this.router.navigate(['/view']);
      return false;
    }
    return true;
  }
}

@Injectable()
export class DesktopGuard {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if ((/Android|iPhone/i.test(window.navigator.userAgent))) {
      this.router.navigate(['/viewm']);
      return false;
    }
    return true;
  }
}
