import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Connection, optionService, GetRoom } from '../../../_service/connected.service';
import { webRTC } from '../../../_service/webrtc.service';

@Component({
  selector: 'app-mobile-show',
  templateUrl: './mobile-show.component.html',
  styleUrls: ['./mobile-show.component.css']
})
export class MobileShowComponent implements OnInit {

  public id: number;
  public data;
  public isMobile = false;
  public margin; topMargin;

  // Show & Hide relate classes
  public beforeStreaming;
  public broadcaster = 'hide';
  public viewer = 'show-inline';
  public start = false;

  // WebSocket
  public logm: string[] = [];
  public isBroadcast = false;
  public broadcast;

  constructor(private route: ActivatedRoute,
    private apiService: Connection,
    public option: optionService,
    public webrtc: webRTC,
    private room: GetRoom
  ) {
  }

  log(msg) {
    this.logm.push(msg);
  }

  ngOnInit() {
    this.start = false;
    this.isMobile = true;
    this.margin = '0px';
    this.topMargin = '0px';
    document.getElementsByTagName('html')[0].style.backgroundColor = '#FFF';
  }

  ngAfterViewInit() {

      this.route.paramMap.subscribe(paramMap => {
        this.id = +paramMap.get('roomid');
        this.apiService.getData(this.id).subscribe((data) => {
          this.showRoomData(data);
        });
      })
  }


  ngOnDestroy() {
    this.viewerStop();
  }

  showRoomData(room) {
    const i = this.room.showRoom(room);
    this.option.id = room.id;
    this.option.data = this.data = room;
    this.option.title = this.room.title;
    this.option.description = this.room.description;
    this.option.owner = this.data.owner.firstname;
    this.option.startTime = this.data.startTime;
    this.option.endTime = this.data.endTime;
    if (this.webrtc.pc != null) {
      this.webrtc.pc.close();
      this.webrtc.sock.close();
      this.webrtc.pc = null;
      this.webrtc.sock = null;
    }
    this.webrtc.openSocket(this.data.connectivity);
  }

  // Viewer section //

  viewerStart() {
    this.start = true;
    this.beforeStreaming = 'hide';
    document.getElementById("banner").style.display = 'none'
  }

  viewerStop() {
    window.scrollTo(0, 0);
    this.beforeStreaming = 'show-block';
    this.start = false;
    document.getElementById("banner").style.display = 'block'
    this.ngAfterViewInit();
  }


}
