<section #fullScreen (mouseenter)='enter()' (mouseleave)='leave()' (mousemove)='mouseOverVideo()'
  [ngClass]="[this.liveContainer,this.viewClass]">
  <div class="w3-display-topleft" id="statusStreaming" [ngClass]="this.option.statusStreaming">Live</div>
  <div *ngIf="this.isBroadcast != 'no'" class="w3-display-bottomright">
    <a id="mic" class="btn" *ngIf="this.mic" (click)="micOff()"><i class="material-icons">mic</i></a>
    <a id="mic" class="btn" *ngIf="!this.mic" (click)="micOn()"><i class="material-icons">mic_off</i></a>
    <a id="terminateViewer" (click)="this.broadcast.stopBroadcast()">End Live</a>
  </div>

  <div id="option" class="w3-display-bottomright" *ngIf="this.isBroadcast == 'no'">
    <div id="options" [ngClass]="this.option.options">
      <app-options></app-options>
    </div>
  </div>

  <a [ngClass]="this.videoArea">
    <video id="video" [ngClass]="[this.option.video,this.option.fullscreenHeight]" playsinline="true"
      [style.background]="this.option.bgColor"></video>
    <video *ngIf="this.isBroadcast != 'no'" id="video2" [ngClass]="[this.option.video,this.option.fullscreenHeight]"
      playsinline="true" [style.background]="this.option.bgColor"></video>
    <video id="videoTextOnly" [ngClass]="[this.option.videoTextOnly,this.option.fullscreenHeight]" playsinline="true"
      [style.background]="this.option.bgColor"></video>
    <audio id="audio" playsinline="true"></audio>

    <div #captiondom id="captioned"
      [ngClass]="[this.option.classCaptionedLine,this.option.classCaptionedWidth,this.option.captioned_default]"
      id="captioned" [style.color]="this.option.color" [style.background]="this.option.captionBG"
      [style.font-size.px]="this.option.size" [style.font-family]="this.option.fontStyle">
    </div>

  </a>


</section>
<div id="options" *ngIf="this.isFix">
  <div class="w3-row">
    <app-fixlinkoptions></app-fixlinkoptions>
  </div>
</div>
<section *ngIf="this.chart" class="stat">
  <div id="rightSection">
    <table style="width:100%">
      <tr>
        <th colspan="5"><i class="material-icons">
            info
          </i>ข้อมูลทั่วไป</th>
      </tr>
      <tr>
        <td>กิจกรรม:</td>
        <td>{{this.option.title}}</td>
      </tr>
      <tr>
        <td>เวลากิจกรรม:</td>
        <td>{{this.option.startTime}} - {{this.option.endTime}}</td>
      </tr>
      <tr>
        <td>สถานะห้อง:</td>
        <td>{{this.option.status}}</td>
      </tr>
      <tr>
        <td>ผู้ชม:</td>
        <td>{{this.option.number}}</td>
      </tr>
      <tr>
        <td>ลิ้งค์คำบรรยาย:</td>
        <td><a
            [href]="sanitize(this.webrtc.host,this.webrtc.text_stream_port)">{{this.webrtc.host}}:{{this.webrtc.text_stream_port}}</a>
        </td>
      </tr>
    </table>


  </div>


  <div id="rightSection">
    <table>
      <tr>
        <th colspan="5"><i class="material-icons">
            favorite
          </i>ข้อมูลการเชื่อมต่อ ICE</th>
      </tr>
      <tr>
        <td colspan="2">สถานะการสตรีม:</td>
        <td>
          <div id="streamStatus">{{this.broadcast.status}}</div>
        </td>
        <td></td>
      </tr>
      <tr style="font-weight: bold;">
        <td></td>
        <td>IP Address</td>
        <td>Port</td>
        <td>Protocol</td>
      </tr>
      <tr>
        <td>Local</td>
        <td>{{this.option.local.address}}</td>
        <td>{{this.option.local.port}}</td>
        <td>{{this.option.local.protocol}}</td>
      </tr>
      <tr>
        <td>Remote</td>
        <td>{{this.option.remote.address}}</td>
        <td>{{this.option.remote.port}}</td>
        <td>{{this.option.remote.protocol}}</td>
      </tr>
    </table>
  </div>

  <div id="rightSection">
    <table>
      <tr>
        <th colspan="3"><i class="material-icons">
            videocam
          </i>วิดีโอ</th>
      </tr>
      <tr>
        <td colspan="3">
          <canvas baseChart id="videoChart" maintainAspectRatio="true"></canvas>
        </td>
      </tr>
      <tr style="font-size: 12px;">
        <td width="33%">Max : {{this.broadcast.maxVideoBitrate}} <small>kbps</small> </td>
        <td width="34%">Current : {{(this.broadcast.videoBitrate * 8 / 1000).toFixed(0)}} <small>kbps</small></td>
        <td width="33%">Min : {{this.broadcast.minVideoBitrate}} <small>kbps</small></td>
      </tr>
    </table>
  </div>

  <div id="rightSection">
    <table>
      <tr>
        <th colspan="3"><i class="material-icons">
            volume_up
          </i>เสียง</th>
      </tr>
      <tr>
        <td colspan="3"> <canvas id="audioChart" maintainAspectRatio="true"></canvas></td>
      </tr>
      <tr style="font-size: 12px;">
        <td>Max : {{this.broadcast.maxAudioBitrate}} <small>kbps</small> </td>
        <td>Current : {{(this.broadcast.audioBitrate * 8 / 1000).toFixed(0)}} <small>kbps</small> </td>
        <td>Min : {{this.broadcast.minAudioBitrate}} <small>kbps</small></td>
      </tr>
    </table>
  </div>


</section>