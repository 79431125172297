<section #fullScreen (mouseenter)='enter()' (mouseleave)='leave()' (mousemove)='mouseOverVideo()'
  [ngClass]="[this.liveContainer,this.viewClass]">
  <div class="w3-display-topleft" id="statusStreaming" [ngClass]="this.option.statusStreaming">Live</div>

  <div id="option" class="w3-display-bottomright">
    <div id="options" [ngClass]="this.option.options">
      <app-mobile-option></app-mobile-option>
    </div>
  </div>

  <a [ngClass]="this.videoArea">
    <video id="video" [ngClass]="[this.option.video,this.option.fullscreenHeight]" playsinline="true"
      [style.background]="this.option.bgColor"></video>
    <video id="videoTextOnly" [ngClass]="[this.option.videoTextOnly,this.option.fullscreenHeight]" playsinline="true"
      [style.background]="this.option.bgColor"></video>
      <audio id="audio" playsinline="true"></audio>

    <div #captiondom id="captioned"
      [ngClass]="[this.option.classCaptionedLine,this.option.classCaptionedWidth,this.option.captioned_default]"
      id="captioned" [style.color]="this.option.color" [style.background]="this.option.captionBG"
      [style.font-size.px]="this.option.size" [style.font-family]="this.option.fontStyle">
    </div>

  </a>
</section>
