export const environment = {
    production: false,
    API_URL: 'https://api.sandbox.rtt.in.th/v3',
    API_PUBLIC_URL: 'https://api.sandbox.rtt.in.th/v3/public',
    API_WS: 'wss://api.sandbox.rtt.in.th:',
    WEBDIS_URL: 'https://webdis.sandbox.rtt.in.th',
    OAUTH: {
        URL: 'https://accounts.sandbox.rtt.in.th/auth/realms/rtt',
        CLIENT_ID: 'viewer',
        RESONSE_TYPE: 'code'
    }
};
