import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { Connection, optionService, GetRoom } from '../../../_service/connected.service';
import { DomSanitizer } from '@angular/platform-browser';
import { webRTC } from '../../../_service/webrtc.service';

@Component({
  selector: 'app-mobile-video',
  templateUrl: './mobile-video.component.html',
  styleUrls: ['./mobile-video.component.css']
})
export class MobileVideoComponent implements OnInit {

  @ViewChild('fullScreen', { static: false }) fullScreen;
  @Input() id;
  @Input() isBroadcast;

  public status: any;

  public videoArea;

  // Show & Hide relate classes

  public liveContainer; statusStreaming;
  public video; videoTextOnly; viewClass;
  public unmuteVolume;
  public muteVolume = false;
  public chart = false;
  public mic = true;
  public isFix = false;

  // Fullscreen

  public exitFullscreen;
  public fullscreenHeight = 'normal-height';
  public isFullscreen = false;
  public timeout = null;
  public time = 0;

  // websocket
  public sock;
  public gotoview;
  public number;
  public lineChart; lineChart2;

  // browser type
  public browser;
  public isMobile;
  public isTablet;

  constructor(
    public option: optionService,
    public webrtc: webRTC,
  ) {

  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    this.videoArea = 'cursor-show';
    this.viewClass = 'liveContainer';
    this.option.caption = '';
  }

  ngOnInit() {
    this.liveContainer = 'show-block';
    this.videoArea = 'cursor-show';
    this.viewClass = 'liveContainer';
   
    clearTimeout(this.timeout);
    this.option.caption = '';
    this.sock = this.webrtc.sock;
    this.webrtc.createSession();
    this.sock.onclose = (e) => {
      const log = 'disconnected from server';
    };
    this.sock.onerror = (e) => {
      console.log(e);
    }
    this.sock.onmessage = (e) => {
      const signaling = JSON.parse(e.data);
      if (signaling.id === 'stat') {
        this.option.number = signaling.numberOfClient;
      }
      if (signaling.id === 'viewerResponse') {
        this.webrtc.processAnswer(signaling);
      } else if (signaling.id === 'iceCandidate') {
        this.webrtc.pc.addIceCandidate(signaling.candidate);
      } else if (signaling.id === 'status') {
        alert(`error while joining '${signaling.code}' - '${signaling.message}'`);
      }
    }
    
  }

  ngAfterViewInit() {
   
  }

  toFullScreen() {

    this.option.exitFullscreen = 'show-inline';
    this.option.full = 'hide';
    this.option.fullscreenHeight = this.fullscreenHeight = 'full-height';
    this.isFullscreen = this.option.isFullscreen = true;
    this.time = 3000;

    const elem = this.fullScreen.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    }
  }

  exitFullScreen() {
    this.time = 0;
    this.option.exitFullscreen = 'hide';
    this.option.full = 'show-inline';
    this.option.fullscreenHeight = this.fullscreenHeight = 'normal-height';
    this.isFullscreen = this.option.isFullscreen = false;
    this.videoArea = 'cursor-show';
    clearTimeout(this.timeout);
    if (document['exitFullscreen']) {
      document['exitFullscreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  enter() {
    this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
    this.option.mouseEnter = true;
  }

  leave() {
    this.option.mouseEnter = false;
    if (!this.option.optionPiority) {
      this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth';
    }
  }

  mouseOverVideo() {
    clearTimeout(this.timeout);

    if (this.isFullscreen) {
      if (this.option.optionPiority || this.option.mouseOptionEnter) {
        this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
        this.videoArea = 'cursor-show';
      } else if (!this.option.mouseOptionEnter) {
        this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'show-smooth';
        this.videoArea = 'cursor-show';
        this.timeout = setTimeout(() => {
          this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth';
          this.videoArea = 'cursor-hide';
        }, this.time);
      }
    } else {
      this.videoArea = 'cursor-show';
    }
  }
  micOn() {
    this.mic = true;
  }

  micOff() {
    this.mic = false;
  }


}
