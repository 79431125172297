import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Connection, GetRoom, optionService } from '../../../_service/connected.service';
import { webRTC } from '../../../_service/webrtc.service';

@Component({
  selector: 'app-mobile-room',
  templateUrl: './mobile-room.component.html',
  styleUrls: ['./mobile-room.component.css']
})
export class MobileRoomComponent implements OnInit {
  public noConnection;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(private apiService: Connection,
    private route: Router,
    public room: GetRoom,
    public option: optionService,
    public webrtc: webRTC,
  ) { }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.noConnection = false;
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.room.rooms = [];
      this.noConnection = true;
    }));
    document.getElementsByTagName('html')[0].style.backgroundColor = 'rgb(29, 36, 52)';
    this.getRoom();
    setInterval(() => {
      this.getRoom();
    }, 60000);
  }

  getRoom() {
    this.apiService.getAllData().forEach(data => {
      this.room.roomData(data['result']);
    });
  }

  onKeyUp(event: any) {
    this.apiService.searchData(event.target.value).forEach(data => {
      this.room.roomData(data['result']);
    });
  }

  getData(id) {
    this.w3_close()
    this.route.navigate(['viewm/' + id]);
  }

  w3_close() {

  }
}
