<div id="options">
    <div class="w3-row">
        <button class="btn" [(colorPicker)]="this.option.color" [cpDisableInput]=true [cpSaveClickOutside]
            [cpPosition]="'left'">สีตัวอักษรคำบรรยาย</button>

        <button class="btn" [(colorPicker)]="this.option.captionBG" [cpDisableInput]=true [cpSaveClickOutside]
            [cpPosition]="'left'">สีพื้นหลังของตัวอักษร</button>
        <button class="btn" id="incFont" (click)="toIntPlus(size,3)">A+</button>
        <input class="btn" id="incFont" (keyup.enter)="setFontSize($event)" value="{{size}}">
        <button class="btn" id="decFont" (click)="toIntMinus(size,3)">A-</button></div>
</div>
