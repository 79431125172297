<!-- !PAGE CONTENT! -->
<div id='content' class="w3-main" [style.margin-left]="margin">
    <!-- Push down content on small screens -->
    <div class="w3-hide-large" [style.margin-top]="topMargin"></div>
  
  
  
    <!-- BEFORE VIEWER -->
    <section id="beforeStreaming" [ngClass]="beforeStreaming">
      <div class="w3-container">
        <div class="w3-row">
          <div class="w3-col m12">
            <button [ngClass]="viewer" class="w3-button w3-ripple w3-red w3-hover-black" id="startViewer"
              (click)="viewerStart()">รับชม</button>
          </div>
        </div>
        <hr>
      </div>
    </section>
  
    <!-- FOR START VIEWER WEB-->
    <div *ngIf="this.start">
      <app-mobile-video></app-mobile-video>
      <div>
        <button (click)='viewerStop()' class="w3-button w3-ripple w3-red w3-hover-black" id="stopViewer">หยุดรับชม</button>
      </div>
    </div>
  
    <!-- Header -->
    <header class="w3-container">
      <div style="float: right">
        {{this.option.number}}
        <i class="material-icons" style="font-size: 16px" >
            visibility
          </i>
      </div>
       
      <div *ngIf="!this.start" id="title">{{this.option.title}}</div>
      
      <div *ngIf="!this.start">
        โดย {{this.option.owner}}
      </div>
  
      <div id="description" class="w3-container">{{this.option.description}}
        
    </div>
  
    </header>

  </div>

