<nav class="w3-sidenav w3-red w3-animate-left w3-collapse w3-top w3-large " id="mySidenav"
  [ngClass]="{'w3-open': this.option.show, 'w3-close':!this.option.show}">

  <div class="w3-container w3-padding-16">
    <div style="text-align: center;">
      <h3>
        <a href="index.html" style="background: no-repeat;color:white;"><img style=" width: 250px;"
            src="../../../assets/img/viewer_logo.png"></a>
      </h3>
      <div class="login">
        <!-- <button *ngIf="!authService.getUser()" id="choice" (click)="login()" class="w3-red"><i class="material-icons">
            supervisor_account
          </i>เข้าสู่ระบบ</button> -->
        <button id="choice" (click)="broadcast()" class="w3-red"><i class="material-icons">
            supervisor_account
          </i>เข้าสู่ระบบ Broadcast</button>
        <!-- <button *ngIf="authService.getUser() && !authService.getUser().realm_access.roles.includes('broadcast')" id="choice" (click)="logout()" class="w3-red">
          <i
            class="material-icons">
            logout
          </i>ออกจากระบบ</button> -->

      </div>
      <input (keyup)="onKeyUp($event)" id="searchRooms" class="w3-input w3-border" type="text"
        placeholder="ค้นหาห้อง...">
    </div>
  </div>
  <ul id="menuViewer">
    <li mat-raised-button matTooltip='{{data.title}}' matTooltipPosition="right" matTooltipClass="tooltip-class"
      *ngFor="let data of this.room.rooms ;let i = index" (click)='this.getData(data.id)' class="listrooms">
      <a>

        <div class="dotdot">{{data.title}} </div>
        <font size="1">
          <b>เริ่ม</b> {{this.room.startDate[i]}} เวลา {{this.room.startTime[i]}}
          <br>
          <b>สิ้นสุด</b> {{this.room.endDate[i]}} เวลา {{this.room.endTime[i]}}
        </font>
        <div [style.color]="this.room.statusColor[i]" style="font-size: 13px; ">
          {{this.room.status[i]}}
          <br *ngIf="this.room.status[i] != ''">
          {{this.room.timeStatus[i]}}
        </div>
      </a>
    </li>
  </ul>
  <ul *ngIf="noConnection" id="menuViewer">
    <h1>
      <b id="notFound">อุปส์!</b>
    </h1>
    <p>เกิดข้อผิดพลาดระหว่างการเชื่อมต่อ โปรดลองใหม่อีกครั้ง</p>
    <button id="reconnectRooms">ลองใหม่</button>
  </ul>
  <ul *ngIf="this.room.rooms.length == 0" id="menuViewer" class="error">
    <!-- Not found Rooms -->
    <div id="notFound">ไม่พบข้อมูล</div>
    <button id="reconnectRooms" (click)="getRoom()">ลองใหม่</button>
  </ul>


  <div style="background-color: black"></div>
</nav>

<router-outlet></router-outlet>