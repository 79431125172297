import { Injectable } from '@angular/core';
import { optionService } from './connected.service';
import { webRTC } from './webrtc.service';
import { Router } from '@angular/router';

declare var $: any;
declare var kurentoUtils: any;

@Injectable({
    providedIn: 'root'
})
export class Broadcast {
    config: any;
    public ws: WebSocket;
    webRtcPeer: any;
    public audioTemp = 0;
    public videoTemp = 0;
    public videoBitrate = 0;
    public maxVideoBitrate = 0;
    public minVideoBitrate;
    public audioBitrate = 0;
    public maxAudioBitrate = 0;
    public minAudioBitrate;
    public localId; remoteId;
    public status = 'CHECKING';
    constructor(
        public webrtc: webRTC,
        public option: optionService,
        public route: Router) {
        this.config = {
            useRTSP: null,
            userMedia: 'WEBM', // WEBM, WEBM_AUDIO_ONLY
            mediaProfile: 'WEBM'
        };
    }

    videoBroadcast(rooms) {
        if (rooms.status === 3 || rooms.status === 4 || rooms.status === 5) {
            this.config.useRTSP = null;
            this.config.mediaProfile = 'WEBM';
            this.connectProxy(this.config);
        } else {
            alert('ห้องอยู่ในสถานะที่ไม่สามารถถ่ายทอดสดได้');
            window.location.reload();
        }
    }

    audioBroadcast(rooms) {
        if (rooms.status === 3 || rooms.status === 4 || rooms.status === 5) {
            this.config.useRTSP = null;
            this.config.mediaProfile = 'WEBM_AUDIO_ONLY';
            this.connectProxy(this.config);
        } else {
            alert('ห้องอยู่ในสถานะที่ไม่สามารถถ่ายทอดสดได้');
            window.location.reload();
        }
    }

    playback(rooms) {
        if (rooms.status === 3 || rooms.status === 4 || rooms.status === 5) {
            this.config.useRTSP = prompt('Enter stream url', 'https://assets.sandbox.rtt.in.th/archive/2019-12-20 17-00-32.mp4');
            if (this.config.useRTSP != null) {
                this.config.mediaProfile = 'WEBM';
                this.connectProxy(this.config);
                if (this.option.local.address == '') {
                    this.option.local.port = 'Playback';
                }
                if (this.option.remote.address == '') {
                    this.option.remote.port = 'Playback';
                }
            } else {
                window.location.reload();
            }
        } else {
            alert('ห้องอยู่ในสถานะที่ไม่สามารถถ่ายทอดสดได้');
            window.location.reload();
        }
    }

    connectProxy(config) {
        // check ws
        if (this.ws != null) {
            this.dispose();
            this.ws.close();
        }
        this.ws = new WebSocket('wss://' + this.webrtc.host + ':' + this.webrtc.webrtc_proxy_port + '/transcription');
        this.ws.onopen = () => {
            this.iceInfomation('Connected');
            this.presenter(this.ws, config);
        };
        this.ws.onerror = (err) => {
            this.status = 'FAILED';
            console.log(err);
            this.iceInfomation(err);
            document.getElementById('streamStatus').style.backgroundColor = 'red';
        };
        this.ws.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);
            if (parsedMessage.response) {
                this.iceInfomation(parsedMessage.response)
            }
            switch (parsedMessage.id) {
                case 'presenterResponse':
                    this.presenterResponse(parsedMessage);
                    break;
                case 'iceCandidate':
                    this.webRtcPeer.addIceCandidate(parsedMessage.candidate);
                    break;
                case 'information':
                    break;
                default:
            }
        };
        this.ws.onclose = () => {
            this.ws.send(JSON.stringify({
                id: 'unpublishStream'
            }));
        };


    }

    iceInfomation(message) {
        const date = new Date();
        let hour;
        let minute;
        if (date.getHours() < 10) {
            hour = '0' + date.getHours();
        } else {
            hour = date.getHours();
        }
        if (date.getMinutes() < 10) {
            minute = '0' + date.getMinutes();
        } else {
            minute = date.getMinutes();
        }

        document.getElementById('ice').innerHTML += hour + ':' + minute + ' ' + message + '<br>';
    }

    stopBroadcast() {
        this.dispose();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    presenter(ws, config) {
        function onIceCandidate(candidate) {
            const message = {
                id: 'iceCandidate',
                candidate: candidate
            };
            ws.send(JSON.stringify(message));

        }

        if (!this.webRtcPeer) {
            $('#captioned').html('กำลังเชื่อมต่อ...');
            let options;
            const video = document.getElementById('video');
            video.style.display = 'none';
            const video2 = document.getElementById('video2');
            // Check config.mediaProfile is WEBM (streaming video+audio or playback video)
            if (config.mediaProfile === 'WEBM') {
                const default_constraints = {
                    audio: true,
                    video: {
                        framerate: 30,
                        width: {
                            min: 640,
                            max: 1920
                        },
                        height: {
                            min: 320,
                            max: 1080
                        }
                    }
                };

                options = {
                    iceServers: [{ urls: 'stun:203.183.172.196:3478' }],
                    localVideo: (config.useRTSP == null) ? video2 : video,
                    onicecandidate: onIceCandidate,
                    mediaConstraints: default_constraints
                };
            } else {
                const default_constraints = {
                    audio: true,
                    video: {
                        framerate: 30,
                        width: {
                            min: 640,
                            max: 1920
                        },
                        height: {
                            min: 320,
                            max: 1080
                        }
                    }
                };
                // streaming audio only
                default_constraints.video = undefined;
                options = {
                    iceServers: [{ urls: 'stun:203.183.172.196:3478' }],
                    localVideo: (config.useRTSP == null) ? null : video,
                    onicecandidate: onIceCandidate,
                    mediaConstraints: default_constraints
                };
            }
            this.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, (callback) => {
                if (callback) {
                    return;
                }
                this.webRtcPeer.generateOffer(onOfferPresenter);
                $('#captioned').html('เริ่มบรรยาย เจ้าหน้าที่กำลังถอดความ...');
                // video.setAttribute('src', config.useRTSP);
                // video.setAttribute('autoplay', 'true');
                video2.setAttribute('src', config.useRTSP);
                video2.setAttribute('autoplay', 'true');
                this.status = 'CONNECTED'
                document.getElementById('streamStatus').style.backgroundColor = 'green';
            });
        }
        function onOfferPresenter(error, offerSdp) {
            if (error) {
                return;
            }
            const message = {
                id: 'presenterJoined',
                useRTSP: config.useRTSP,
                mediaProfile: config.mediaProfile,
                sdpOffer: offerSdp
            };
            ws.send(JSON.stringify(message));
            ws.send(JSON.stringify({
                id: 'publishStream'
            }));
        }
    }

    dispose() {
        if (this.webRtcPeer) {
            this.webRtcPeer.dispose();
            this.webRtcPeer = null;
        }
    }

    presenterResponse(message) {
        if (message.response !== 'accepted') {
            const errorMsg = message.message ? message.message : 'Unknow error';
            this.dispose();
        } else {
            this.webRtcPeer.processAnswer(message.sdpAnswer);
        }
    }

    getstats() {
        this.printStats(this.webRtcPeer);
    }

    printStats(webRtcPeer) {
        this.getBrowserOutgoingVideoStats(webRtcPeer, (error, stats) => { });
    }

    getBrowserOutgoingVideoStats(webRtcPeer, callback) {
        if (!webRtcPeer) { return callback('Cannot get stats from null webRtcPeer'); }
        const peerConnection = webRtcPeer.peerConnection;
        if (!peerConnection) { return callback('Cannot get stats from null peerConnection'); }

        peerConnection.getStats().then(stats => {
            stats.forEach(stat => {
                let preVideo;
                let preAudio;
                if ((stat.type === 'outbound-rtp' && stat.kind === 'video') || (stat.id).includes(('RTCOutboundRTPVideoStream'))) {
                    preVideo = parseInt(stat.bytesSent, 10);
                    this.videoBitrate = Math.abs(preVideo - this.videoTemp);
                    this.videoTemp = preVideo;
                    this.option.videoBitrate.push((this.videoBitrate * 8 / 1000).toFixed(0));
                    if (this.maxVideoBitrate <= parseFloat((this.videoBitrate * 8 / 1000).toFixed(0))) {
                        this.maxVideoBitrate = parseFloat((this.videoBitrate * 8 / 1000).toFixed(0));
                    }
                    if (!this.minVideoBitrate) {
                        this.minVideoBitrate = parseFloat((this.videoBitrate * 8 / 1000).toFixed(0));
                    } else if (this.minVideoBitrate >= parseFloat((this.videoBitrate * 8 / 1000).toFixed(0))) {
                        this.minVideoBitrate = parseFloat((this.videoBitrate * 8 / 1000).toFixed(0));
                    }
                    this.updateGraph('video');
                }
                if ((stat.type === 'outbound-rtp' && stat.kind === 'audio') || (stat.id).includes(('RTCOutboundRTPAudioStream'))) {
                    preAudio = parseInt(stat.bytesSent, 10);
                    this.audioBitrate = Math.abs(preAudio - this.audioTemp);
                    this.audioTemp = preAudio;
                    this.option.audioBitrate.push((this.audioBitrate * 8 / 1000).toFixed(0));
                    if (this.maxAudioBitrate <= parseFloat((this.audioBitrate * 8 / 1000).toFixed(0))) {
                        this.maxAudioBitrate = parseFloat((this.audioBitrate * 8 / 1000).toFixed(0));
                    }
                    if (!this.minAudioBitrate) {
                        this.minAudioBitrate = parseFloat((this.audioBitrate * 8 / 1000).toFixed(0));
                    } else if (this.minAudioBitrate >= parseFloat((this.audioBitrate * 8 / 1000).toFixed(0))) {
                        this.minAudioBitrate = parseFloat((this.audioBitrate * 8 / 1000).toFixed(0));
                    }

                    this.updateGraph('audio');
                }
                if (stat.nominated || stat.selected) {
                    this.localId = stat.localCandidateId;
                    this.remoteId = stat.remoteCandidateId;
                }
                if (stat.id === this.localId) {
                    this.option.local = {
                        address: stat.address || stat.ip,
                        port: stat.port,
                        protocol: stat.protocol
                    };
                }
                if (stat.id === this.remoteId) {
                    this.option.remote = {
                        address: stat.address || stat.ip,
                        port: stat.port,
                        protocol: stat.protocol
                    };
                }
            });
        });
    }
    updateGraph(graph) {
        if (graph === 'video') {
            if (this.option.videoBitrate.length >= 30) {
                this.option.videoBitrate.shift();
            }
            this.option.videoLineChart.data.labels.push('');
            this.option.videoLineChart.update()
        }
        if (graph === 'audio') {

            if (this.option.audioBitrate.length >= 30) {
                this.option.audioBitrate.shift();
            }
            this.option.audioLineChart.data.labels.push('');
            this.option.audioLineChart.update()
        }


    }
}
