import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'src/app/_service/auth.service';
import { Connection } from 'src/app/_service/connected.service';


@Component({
  templateUrl: '404.component.html',
  styleUrls: ['404.component.scss'],
})
export class P404Component implements OnInit {

  constructor(private authService:AuthService, private router: Router, private oauthService: OAuthService, private apiService: Connection,) { }

  ngOnInit(){
    this.getRoom();
  }

  getRoom(){
    this.apiService.getDataByowner(this.authService.getUser().id).subscribe(data => {});
  }
  logout() {
    this.authService.logout();
  }

  viewer() {
    this.router.navigate(['/view'])
  }
}
