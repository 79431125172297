import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoomComponent } from './components/pc/desktop-room/room.component';
import { Connection, optionService } from './_service/connected.service';
import { webRTC } from './_service/webrtc.service';
import { ShowComponent } from './components/pc/desktop-show/show.component';
import { OptionsComponent } from './components/pc/desktop-options/options.component';
import { BroadcastComponent } from './components/pc/desktop-broadcast/broadcast.component';
import { AuthGuardService, DesktopGuard, MobileGuard } from './_service/auth.guard.service';
import { AuthService } from './_service/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VideoComponent } from './components/pc/desktop-video/video.component';
import { FixlinkoptionsComponent } from './components/pc/desktop-fixlinkoptions/fixlinkoptions.component';
import { Broadcast } from './_service/broadcast.service';
import { MobileRoomComponent } from './components/mobile/mobile-room/mobile-room.component';
import { MobileVideoComponent } from './components/mobile/mobile-video/mobile-video.component';
import { MobileShowComponent } from './components/mobile/mobile-show/mobile-show.component';
import { MobileOptionComponent } from './components/mobile/mobile-option/mobile-option.component';
import { OauthConfigService } from './_service/oauth-config.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { P404Component } from './components/error/404.component';

export function init_app(authConfigService: OauthConfigService) {
  return () => authConfigService.initAuth();
}

@NgModule({
  declarations: [
    AppComponent,
    RoomComponent,
    ShowComponent,
    OptionsComponent,
    BroadcastComponent,
    VideoComponent,
    FixlinkoptionsComponent,
    MobileRoomComponent,
    MobileVideoComponent,
    MobileShowComponent,
    MobileOptionComponent,
    P404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ColorPickerModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    OAuthModule.forRoot(),
  ],
  providers: [Connection, optionService, AuthGuardService,
    AuthService, webRTC, Broadcast, DesktopGuard, MobileGuard,
    OauthConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [OauthConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
