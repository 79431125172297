import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Connection, optionService } from './_service/connected.service';
import { fromEvent, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {


  public isMobile: any;
  public noConnection: any;
  public show: any;
  public sideNav = 'w3-open';
  public header = true;

  onlineEvent: Observable<Event> = new Observable;
  offlineEvent: Observable<Event> = new Observable;
  subscriptions: Subscription[] = [];


  constructor(
    public option: optionService,
    public elementRef: ElementRef) { }

  ngOnInit() {
    if (/Android|iPhone/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      this.option.isMobile = true;
      document.getElementsByTagName('html')[0].style.backgroundColor = '#1d2434';
    } else {
      this.isMobile = false;
      this.option.isMobile = false;
    }



    if (window.location.href.includes('channel')) {
      this.header = false;
    }

  }

  w3_open() {
    this.option.show = true;
    this.show = true;
    this.option.sideNav = 'w3-open';
  }

  w3_close() {
    this.option.show = false;
    this.show = false;
    this.option.sideNav = 'w3-close';
  }
}
