import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { optionService } from '../../../_service/connected.service';
import { VideoComponent } from '../desktop-video/video.component';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';

declare var $: any;


@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit, OnDestroy {

  public size = 36;
  public mode = 'video-text';
  public classCaptionedLine = 'captioned-line-2';
  public classCaptionedWidth = 'captioned-width-70';
  public captioned_default = 'captioned_default';
  private temp: any[] = [];
  public settingCaptionedLine; settingCaptionedWidth;
  public dropupContent = 'hide';
  public twoLineActive; threeLineActive; otherLineActive: number = 0;
  public widthHundredActive; widthNineActive; widthEightActive; widthSevenActive;
  public videoActice; audioActice; textOnlyActive;
  public FreesiaUPCActive; THNiramitASActive; AngsanaNewActive; CordiaNewActive;
  public mouseEnter;
  public video = document.getElementById('video') as HTMLVideoElement;
  public audio = document.getElementById('audio') as HTMLAudioElement;

  // Fullscreen

  public exitFullscreen; full;
  public url = '';
  customWidth: number = 70;

  fontWeight = 'normal'

  @ViewChild('settings', { static: false }) settings;
  @ViewChild('myDrop', { static: false }) ngbDropdown;

  constructor(
    public option: optionService,
    public videoCom: VideoComponent) {
    document.addEventListener('click', () => { this.mouseUp(); });
    setTimeout(() => {
      if (this.video.paused && this.audio.paused) {
        this.option.isPlay = false;
      }
    }, 1000)
  }

  ngOnDestroy() {


  }

  ngOnInit() {

    // this.setMode('video-text');
    // this.setFont('AngsanaNew');
    // this.setCaptionedLine(2);
    // this.setWidth(70);

    if (localStorage.getItem('CaptionedLine')) this.setCaptionedLine(parseInt(localStorage.getItem('CaptionedLine'), 10))
    if (localStorage.getItem('size')) this.option.size = this.size = parseInt(localStorage.getItem('size'), 10)
    if (localStorage.getItem('fontStyle')) this.setFont('' + localStorage.getItem('fontStyle'));
    if (localStorage.getItem('mode')) this.setMode('' + localStorage.getItem('mode'));
    if (localStorage.getItem('widths')) this.setWidth(parseInt(localStorage.getItem('widths'), 10))
    if (localStorage.getItem('color')) this.option.color = localStorage.getItem('color');
    if (localStorage.getItem('captionBG')) this.option.captionBG = localStorage.getItem('captionBG');
    if (localStorage.getItem('bgColor')) this.option.bgColor = localStorage.getItem('bgColor');
    if (localStorage.getItem('fontWeight')) {
      this.option.fontWeight = localStorage.getItem('fontWeight');
      this.setFontWeight(this.option.fontWeight)
    }

    this.url = '' + window.location.href;
    let urlObject: any;
    urlObject = this.option.getAllUrlParams(this.url);
    if (urlObject.width !== undefined) {
      if (urlObject.width === '100') {
        this.setWidth(100)
      } else if (urlObject.width === '70') {
        this.setWidth(70)
      } else if (urlObject.width === '80') {
        this.setWidth(80)
      } else if (urlObject.width === '90') {
        this.setWidth(90)
      }
    }
    if (urlObject.line !== undefined) {
      if (urlObject.line === '3') {
        this.setCaptionedLine(3);
        this.threeLineActive = 'active';
        this.twoLineActive = '';
      } else if (urlObject.line === '2') {
        this.setCaptionedLine(2);
        this.twoLineActive = 'active';
        this.threeLineActive = '';
      } else {
        this.setCaptionedLine(parseInt(urlObject.line));
      }
    }
    if (urlObject.video !== undefined && urlObject.video === 'false') {
      this.option.videoTextOnly = 'show-inline';
      this.option.video = 'hide';
    }
    if (urlObject.font !== undefined) {
      let font = true;
      switch (urlObject.font) {
        case ('FreesiaUPC'):
          this.FreesiaUPCActive = 'active';
          break;
        case ('THNiramitAS'):
          this.THNiramitASActive = 'active';
          break;
        case ('AngsanaNew'):
          this.AngsanaNewActive = 'active';
          break;
        case ('CordiaNew'):
          this.CordiaNewActive = 'active';
          break;
        default:
          this.AngsanaNewActive = 'active';
          font = false;
          break;
      }
      this.option.fontStyle = font ? urlObject.text : 'AngsanaNewActive';
    }
    if (urlObject.fontColor !== undefined) {
      const temp = this.splitValue(urlObject.fontColor, 6);
      const temp2 = temp.split(' ');

      const d = this.hexToR(temp2[0]);
      if (temp2[1] !== '') {
        this.option.color = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
      } else {
        this.option.color = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
      }
      this.option.color = '#' + temp2[0];
    }

    if (urlObject.captionColor !== undefined) {
      const temp = this.splitValue(urlObject.captionColor, 6);
      const temp2 = temp.split(' ');
      const d = this.hexToR(temp2[0]);
      if (temp2[1] !== '') {
        this.option.captionBG = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
      } else {
        this.option.captionBG = '' + 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
      }
    }
    if (urlObject.bgColor !== undefined) {
      const temp = this.splitValue(urlObject.bgColor, 6);
      const temp2 = temp.split(' ');
      const d = this.hexToR(temp2[0]);
      if (temp2[1] !== '') {
        this.option.bgColor = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
      } else {
        this.option.bgColor = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
      }
    }
    if (urlObject.fullscreen !== undefined && urlObject.fullscreen === 'true') {
      setTimeout(() => this.toFullScreen(), 1000);

    }

  }

  splitValue(value, index) {
    return value.substring(0, index) + ' ' + value.substring(index);
  }

  hexToR(h) {
    const a = parseInt((this.cutHex(h)).substring(0, 2), 16);
    const b = parseInt((this.cutHex(h)).substring(2, 4), 16);
    const c = parseInt((this.cutHex(h)).substring(4, 6), 16);
    return [a, b, c];
  }
  cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }

  // Viewer section //

  toIntPlus(size, change) {
    this.option.size = this.size = parseInt(size, 10) + parseInt(change, 10);
    if (this.size > 200) {
      this.option.size = this.size = 200;
    }
    localStorage.setItem('size', '' + this.option.size);

  }
  toIntMinus(size, change) {
    this.option.size = this.size = parseInt(size, 10) - parseInt(change, 10);
    if (this.size < 10) {
      this.option.size = this.size = 10;
    }
    localStorage.setItem('size', '' + this.option.size);
  }


  setFontSize(size) {
    this.option.size = size.target.value;
    localStorage.setItem('size', '' + this.option.size);
  }

  setFont(fontStyle) {
    this.FreesiaUPCActive = this.THNiramitASActive = this.AngsanaNewActive = this.CordiaNewActive = '';
    if (fontStyle === 'FreesiaUPC') { this.FreesiaUPCActive = 'active'; }
    if (fontStyle === 'THNiramitAS') { this.THNiramitASActive = 'active'; }
    if (fontStyle === 'AngsanaNew') { this.AngsanaNewActive = 'active'; }
    if (fontStyle === 'CordiaNew') { this.CordiaNewActive = 'active'; }
    this.option.fontStyle = fontStyle;
    localStorage.setItem('fontStyle', fontStyle);
  }

  setFontWeight(fontWeight) {
    this.fontWeight = fontWeight
    this.option.fontWeight = fontWeight;
    localStorage.setItem('fontWeight', fontWeight);
    document.getElementById('captioned').style.fontWeight = fontWeight;
  }


  setMode(mode) {
    this.textOnlyActive = this.videoActice = this.audioActice = '';
    let audio = document.getElementById('audio') as HTMLAudioElement;
    audio.muted = false;
    if (mode === 'text-only') {
      if (this.mode !== 'text-only') {
        this.settingCaptionedLine = this.settingCaptionedWidth = 'hide';
        this.temp[0] = this.classCaptionedWidth;
        this.temp[1] = this.classCaptionedLine;
        this.option.classCaptionedWidth = this.classCaptionedWidth = '';
        this.option.classCaptionedLine = this.classCaptionedLine = '';
        this.option.captioned_default = this.captioned_default = 'captioned-text-only';
        this.option.videoTextOnly = 'show-inline';
        this.option.video = 'hide';
      }
      this.mode = 'text-only';
      this.option.mode = 'text-only';
      this.textOnlyActive = 'active';
      audio.muted = true;
    }

    if (mode === 'video-text') {
      this.enableVideo()
      if (this.mode !== 'video-text') {
        if (this.mode === 'text-only') {
          this.option.classCaptionedWidth = this.classCaptionedWidth = this.temp[0];
          this.option.classCaptionedLine = this.classCaptionedLine = this.temp[1];
          this.settingCaptionedLine = this.settingCaptionedWidth = 'inline';
          this.option.videoTextOnly = 'hide';
        }
        this.option.video = 'show-inline';
        this.option.captioned_default = this.captioned_default = 'captioned-default';
      }
      this.option.mode = this.mode = 'video-text';
      this.videoActice = 'active';
    }

    if (mode === 'audio-text') {
      if (this.mode !== 'audio-text') {
        this.disableVideo();
        if (this.mode === 'text-only') {
          this.option.classCaptionedWidth = this.classCaptionedWidth = this.temp[0];
          this.option.classCaptionedLine = this.classCaptionedLine = this.temp[1];
          this.settingCaptionedLine = this.settingCaptionedWidth = 'inline';
          this.option.videoTextOnly = 'hide';
        }
        this.option.video = 'show-inline';
        this.option.captioned_default = this.captioned_default = 'captioned-default';
      }
      this.option.mode = this.mode = 'audio-text';
      this.audioActice = 'active';
    }
    localStorage.setItem('mode', '' + this.option.mode);
  }

  setWidth(CaptionedWidth) {
    this.customWidth = CaptionedWidth;
    this.option.widths = CaptionedWidth;
    this.widthHundredActive = this.widthNineActive = this.widthEightActive = this.widthSevenActive = '';

    console.log(CaptionedWidth);


    if (CaptionedWidth === 100) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-100';
      this.widthHundredActive = 'active';
    } else if (CaptionedWidth === 90) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-90';
      this.widthNineActive = 'active';
    } else if (CaptionedWidth === 80) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-80';
      this.widthEightActive = 'active';
    } else if (CaptionedWidth === 70) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-70';
      this.widthSevenActive = 'active';
    } else {
      var margin = (100 - CaptionedWidth) / 2;
      $('#captioned').removeClass('.captioned-default').css({ 'width': `${CaptionedWidth}%`, 'margin-left': `${margin}%` });
      this.option.classCaptionedWidth = this.classCaptionedWidth = `captioned-width-${CaptionedWidth}`;

    }

    localStorage.setItem('widths', '' + CaptionedWidth);
  }

  setCaptionedLine(CaptionedLine) {
    this.otherLineActive = CaptionedLine;
    this.option.CaptionedLine = CaptionedLine;
    this.twoLineActive = this.threeLineActive = '';

    if (CaptionedLine === 3) {
      this.option.classCaptionedLine = this.classCaptionedLine = 'captioned-line-3';
      this.threeLineActive = 'active';
      $('#captioned').removeClass('captioned-line-2').addClass('captioned-line-3');
    } else if (CaptionedLine === 2) {
      this.option.classCaptionedLine = this.classCaptionedLine = 'captioned-line-2';
      this.twoLineActive = 'active';
      $('#captioned').removeClass('captioned-line-3').addClass('captioned-line-2');
    } else {
      this.option.classCaptionedLine = this.classCaptionedLine = `captioned-line-${CaptionedLine}`;
      $('#captioned').removeClass(this.option.classCaptionedLine).css({ 'max-height': `${1.2 * CaptionedLine}em` });

    }

    localStorage.setItem('CaptionedLine', '' + CaptionedLine);
    $('#captioned').animate({ scrollTop: $(document).height() }, 10);
  }

  toggleActive() {
    if (this.option.optionStatus === 'Inactive') {
      this.option.optionStatus = 'Active';
      this.option.optionPiority = true;
    } else if (this.option.optionStatus === 'Active') {
      document.removeEventListener('click', () => { });
      this.option.optionStatus = 'Inactive';
      this.option.optionPiority = false;
      this.ngbDropdown.close();
    }
  }

  clearText() {
    this.option.clearText();
  }

  enter() {
    this.option.mouseOptionEnter = true;
  }

  leave() {
    this.option.mouseOptionEnter = false;

  }

  mouseUp() {
    if (this.ngbDropdown.isOpen()) {
      if (!this.option.mouseOptionEnter) {
        this.ngbDropdown.close();
        this.option.optionStatus = 'Inactive';
        this.option.optionPiority = false;
        this.videoCom.mouseOverVideo();

        if (!this.option.mouseEnter) {
          this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth2';
        }
      }
    }
  }

  toFullScreen() {
    this.videoCom.toFullScreen();
    this.option.full = 'hide';
    this.option.exitFullscreen = 'show-inline';
  }

  exitFullScreen() {
    this.videoCom.exitFullScreen();
    this.full = 'show-inline';
    this.exitFullscreen = 'hide';
  }

  disableVideo() {
    $('#video').css('opacity', 0);
    this.option.visibility = 'hide';
    this.option.visibilityOff = 'show-block';
  }

  enableVideo() {
    $('#video').css('opacity', 1);
    this.option.visibility = 'show-block';
    this.option.visibilityOff = 'hide';
  }


  setColor(e) {
    localStorage.setItem('color', e);
  }

  captionBG(e) {
    localStorage.setItem('captionBG', e);
  }

  bgDisplay(e) {
    localStorage.setItem('bgColor', e);
  }


  playv() {
    this.video.pause();
    this.audio.pause();
    this.video.play();
    this.audio.play();
    this.option.isPlay = true;
  }

  pausev() {
    this.video.pause();
    this.audio.pause();
    this.option.isPlay = false;
  }


  otherCaptionedLine() {
    Swal.fire({
      text: 'ระหว่าง 1-100 บรรทัด',
      title: 'ระบุจำนวนบรรทัด',
      input: 'number',
      inputAttributes: {
        min: '1',
        max: '100'
      },
      validationMessage: 'จำนวนบรรทัดไม่ถูกต้อง!'
    }).then((result) => {
      if (result.value) {
        var count = parseInt(result.value);
        this.setCaptionedLine(count);
      }
    })

  }

  changeWidth(event) {
    var width = parseInt(event.target.value);
    if (width >= 10 && width <= 100) {
      this.setWidth(parseInt(event.target.value));
    }
  }

  checkOtherDropdownWidthActive() {
    var listWidth = [70, 80, 90, 100]
    return !listWidth.includes(this.customWidth);
  }

  saveText() {
    let url = '' + window.location.href;
    let path = url.split('/');
    let roomid = path[path.length - 1].split('?');
    let text = localStorage.getItem("room_id_" + roomid[0]);
    let blob = new Blob([text],
      { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, roomid[0] + "_viewer.txt");
  }

}
