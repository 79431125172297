<div class="text-right">
  <div ngbDropdown placement="top-right" [autoClose]="false" class="d-inline-block" #myDrop="ngbDropdown"
    (mouseenter)='enter()' (mouseleave)='leave()'>
    <a *ngIf="this.option.isTablet">
      <a *ngIf="!this.option.isPlay" class="btn" mat-raised-button matTooltip="Play" matTooltipPosition="above" (click)="playv()"><i class="material-icons">play_arrow</i></a>
      <a *ngIf="this.option.isPlay" class="btn" mat-raised-button matTooltip="Pause" matTooltipPosition="above" (click)="pausev()"><i class="material-icons">pause</i></a>
    </a>
   
    <a class="btn" mat-raised-button matTooltip="Save Text" matTooltipPosition="above" matTooltipClass="mat-tooltip" (click)='saveText()'> <i
      class="material-icons">save</i></a>
    <a class="btn" mat-raised-button matTooltip="Settings" matTooltipPosition="above" matTooltipClass="mat-tooltip" id="dropdownBasic2" ngbDropdownToggle (click)='toggleActive()'> <i
        class="material-icons">settings</i></a>
    <a [ngClass]="this.option.full"><a class="btn" mat-raised-button matTooltipClass="mat-tooltip" matTooltip="Go Fullscreen" matTooltipPosition="above" (click)="toFullScreen()"><i class="material-icons"
          style="font-size: 30px;">fullscreen</i></a></a>
    <a [ngClass]="this.option.exitFullscreen"><a class="btn" mat-raised-button matTooltipClass="mat-tooltip" matTooltip="Exit Fullscreen" matTooltipPosition="above" (click)="exitFullScreen()"><i class="material-icons"
          style="font-size: 30px;">fullscreen_exit</i></a></a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" #settings>

      <div ngbDropdownItem ngbDropdown placement="left-bottom">
        <div ngbDropdownToggle class=" btn-outline-primary">
          <a class="btn" id="dropdownBasic3">รูปแบบการแสดงผล</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            <div class="btn" [ngClass]="this.videoActice" (click)="setMode('video-text')" ngbDropdownItem>วิดีโอและเสียง</div>
            <div class="btn" [ngClass]="this.audioActice" (click)="setMode('audio-text')" ngbDropdownItem>เสียง</div>
            <div class="btn" [ngClass]="this.textOnlyActive" (click)="setMode('text-only')" ngbDropdownItem>คำบรรยาย</div>
          </div>
        </div>
      </div>

      <div class="btn-outline-primary" ngbDropdownItem ngbDropdown>
        <a  class="btn" id="dropdownBasic3" (click)='clearText()'>เคลียร์คำบรรยาย</a>
    </div>

      <div ngbDropdownItem ngbDropdown placement="left-bottom" [ngClass]="this.settingCaptionedLine">
        <div ngbDropdownToggle class=" btn-outline-primary">
          <a class="btn" id="dropdownBasic3">จำนวนบรรทัดคำบรรยาย</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            <div class="btn" [ngClass]="{'active': otherLineActive == 1}" (click)="setCaptionedLine(1)" ngbDropdownItem>1 บรรทัด</div>
            <div class="btn" [ngClass]="this.twoLineActive" (click)="setCaptionedLine(2)" ngbDropdownItem>2 บรรทัด</div>
            <div class="btn" [ngClass]="this.threeLineActive" (click)="setCaptionedLine(3)" ngbDropdownItem>3 บรรทัด</div>
            <div class="btn" [ngClass]="{'active': otherLineActive > 3 }" (click)="otherCaptionedLine()" ngbDropdownItem>อื่นๆ<span *ngIf="otherLineActive > 3">({{otherLineActive}})</span></div>
          </div>
        </div>
      </div>

      <div ngbDropdownItem ngbDropdown  #widthCaptionDropdown="ngbDropdown" autoClose="outside" placement="left-bottom" [ngClass]="this.settingCaptionedWidth">
        <div ngbDropdownToggle class=" btn-outline-primary">
          <a class="btn" id="dropdownBasic3">ความยาวของคำบรรยาย</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            
           
            <div class="btn" [ngClass]="this.widthSevenActive" (click)="setWidth(70); widthCaptionDropdown.close()" ngbDropdownItem>70% ของจอ</div>
            <div class="btn" [ngClass]="this.widthEightActive" (click)="setWidth(80); widthCaptionDropdown.close()" ngbDropdownItem>80% ของจอ</div>
            <div class="btn" [ngClass]="this.widthNineActive" (click)="setWidth(90); widthCaptionDropdown.close()" ngbDropdownItem>90% ของจอ</div>
            <div class="btn" [ngClass]="this.widthHundredActive" (click)="setWidth(100); widthCaptionDropdown.close()" ngbDropdownItem>100% ของจอ</div>
            <div ngbDropdownItem ngbDropdown autoClose="outside" placement="left-bottom">
              <div ngbDropdownToggle class=" btn-outline-primary">
                <a class="btn" id="dropdownBasic3">อื่นๆ</a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                  <div class="row" >
                    <div class="col-12">
                      <input (input)="changeWidth($event)" type="range" ngbDropdownItem min="10" max="100" [value]="customWidth">
                    </div>
                    <div class="col-12">
                      <input (keyup)="changeWidth($event)" [value]="customWidth" name="widths" id="searchRooms" class="w3-input w3-border" type="number" min="10" max="100" placeholder="ความกว้าง(%)">
                    </div>
                    
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      

      <div ngbDropdownItem ngbDropdown placement="left-bottom">
        <div ngbDropdownToggle class="btn-outline-primary">
          <a class="btn" id="dropdownBasic3">ตัวอักษร</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            <div class="btn" [ngClass]="this.FreesiaUPCActive" (click)="setFont('FreesiaUPC')" ngbDropdownItem>
              FreesiaUPC</div>
            <div class="btn" [ngClass]="this.THNiramitASActive" (click)="setFont('THNiramitAS')" ngbDropdownItem>Th
              Niramit AS</div>
            <div class="btn" [ngClass]="this.AngsanaNewActive" (click)="setFont('AngsanaNew')" ngbDropdownItem>Angsana
              New</div>
            <div class="btn" [ngClass]="this.CordiaNewActive" (click)="setFont('CordiaNew')" ngbDropdownItem>Cordia New
            </div>
          </div>
        </div>
      </div>

      <div ngbDropdownItem ngbDropdown placement="left-bottom">
        <div ngbDropdownToggle class="btn-outline-primary">
          <a class="btn" id="dropdownBasic3">ความหนาตัวอักษร</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            <div class="btn" [class.active]="this.fontWeight == 'normal'" (click)="setFontWeight('normal')" ngbDropdownItem>
              ปกติ</div>
            <div class="btn" [class.active]="this.fontWeight == 'bold'" (click)="setFontWeight('bold')" ngbDropdownItem>
              ตัวหนา</div>
          </div>
        </div>
      </div>

      <div ngbDropdownItem ngbDropdown placement="left-bottom" autoClose="outside">
        <div ngbDropdownToggle class=" btn-outline-primary">
          <a class="btn" id="dropdownBasic3">สี</a>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">

            <button class="btn" ngbDropdownItem [(colorPicker)]="this.option.color" [cpDisableInput]=false
              [cpSaveClickOutside] [cpPosition]="'left'" (colorPickerChange)="setColor($event)" >สีตัวอักษรคำบรรยาย</button>

            <button class="btn" ngbDropdownItem [(colorPicker)]="this.option.captionBG" [cpDisableInput]=false
              [cpSaveClickOutside] [cpPosition]="'left'" (colorPickerChange)="captionBG($event)">สีพื้นหลังของตัวอักษร</button>
              <button class="btn" ngbDropdownItem [(colorPicker)]="this.option.bgColor" [cpDisableInput]=false
              [cpSaveClickOutside] [cpPosition]="'left'" (colorPickerChange)="bgDisplay($event)">สีพื้นหลังของจอแสดงผล</button>
          </div>
        </div>
      </div>

     

      <div class="btn-outline-primary" ngbDropdownItem ngbDropdown>
        <button class="btn" id="incFont" (click)="toIntPlus(size,3)">A+</button>
        <input class="btn" id="incFont" (keyup)="setFontSize($event)" value="{{size}}">
        <button class="btn" id="decFont" (click)="toIntMinus(size,3)">A-</button></div>



    </div>
  </div>
</div>