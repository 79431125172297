import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { optionService } from '../../../_service/connected.service';
import { MobileVideoComponent } from '../mobile-video/mobile-video.component';

declare var $: any;

@Component({
  selector: 'app-mobile-option',
  templateUrl: './mobile-option.component.html',
  styleUrls: ['./mobile-option.component.css']
})
export class MobileOptionComponent implements OnInit {

  public size = 21;
  public mode = 'video-text';
  public classCaptionedLine = 'captioned-line-2';
  public classCaptionedWidth = 'captioned-width-70';
  public captioned_default = 'captioned_default';
  private temp: any[] = [];
  public settingCaptionedLine; settingCaptionedWidth;
  public dropupContent = 'hide';
  public twoLineActive; threeLineActive;
  public widthHundredActive; widthNineActive; widthEightActive; widthSevenActive;
  public videoActice; audioActice; textOnlyActive;
  public FreesiaUPCActive; THNiramitASActive; AngsanaNewActive; CordiaNewActive;
  public mouseEnter;
  public video = document.getElementById('video') as HTMLVideoElement;
  public audio = document.getElementById('audio') as HTMLAudioElement;

  // Fullscreen

  public exitFullscreen; full;
  public url = '';


  @ViewChild('settings', { static: false }) settings;
  @ViewChild('myDrop', { static: false }) ngbDropdown;

  constructor(
    public option: optionService,
    public videoCom: MobileVideoComponent) {
    document.addEventListener('click', () => { this.mouseUp(); });
    // setTimeout(() => {
    //   if(this.video.paused && this.audio.paused) {
    //     this.option.isPlay = false;
    //   }
    // },1000)
  }

  ngOnDestroy() {


  }

  ngOnInit() {

    this.setMode('video-text');

    if (window.location.href.includes('channel')) {

      this.setMode('audio-text');
      this.option.size = this.size = 68;
      this.setFont('AngsanaNew');
      this.setWidth(100);
      this.setCaptionedLine(2);
    } else {
      this.url = '' + window.location.href;
      let urlObject: any;
      urlObject = this.option.getAllUrlParams(this.url);
      if (urlObject.width !== undefined) {
        if (urlObject.width === '100') {
          this.setWidth(100)
        } else if (urlObject.width === '70') {
          this.setWidth(70)
        } else if (urlObject.width === '80') {
          this.setWidth(80)
        } else if (urlObject.width === '90') {
          this.setWidth(90)
        }
      }
      if (urlObject.line !== undefined) {
        if (urlObject.line === '3') {
          this.setCaptionedLine(3);
          this.threeLineActive = 'active';
          this.twoLineActive = '';
        } else if (urlObject.line === '2') {
          this.setCaptionedLine(2);
          this.twoLineActive = 'active';
          this.threeLineActive = '';
        }
      }
      if (urlObject.video !== undefined && urlObject.video === 'false') {
        this.option.videoTextOnly = 'show-inline';
        this.option.video = 'hide';
      }
      if (urlObject.font !== undefined) {
        let font = true;
        switch (urlObject.font) {
          case ('FreesiaUPC'):
            this.FreesiaUPCActive = 'active';
            break;
          case ('THNiramitAS'):
            this.THNiramitASActive = 'active';
            break;
          case ('AngsanaNew'):
            this.AngsanaNewActive = 'active';
            break;
          case ('CordiaNew'):
            this.CordiaNewActive = 'active';
            break;
          default:
            this.AngsanaNewActive = 'active';
            font = false;
            break;
        }
        this.option.fontStyle = font ? urlObject.text : 'AngsanaNew';
      }
      if (urlObject.fontColor !== undefined) {
        const temp = this.splitValue(urlObject.fontColor, 6);
        const temp2 = temp.split(' ');

        const d = this.hexToR(temp2[0]);
        if (temp2[1] !== '') {
          this.option.color = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
        } else {
          this.option.color = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
        }
        this.option.color = '#' + temp2[0];
      }

      if (urlObject.captionColor !== undefined) {
        const temp = this.splitValue(urlObject.captionColor, 6);
        const temp2 = temp.split(' ');
        const d = this.hexToR(temp2[0]);
        if (temp2[1] !== '') {
          this.option.captionBG = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
        } else {
          this.option.captionBG = '' + 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
        }
      }
      if (urlObject.bgColor !== undefined) {
        const temp = this.splitValue(urlObject.bgColor, 6);
        const temp2 = temp.split(' ');
        const d = this.hexToR(temp2[0]);
        if (temp2[1] !== '') {
          this.option.bgColor = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',' + temp2[1] + ')';
        } else {
          this.option.bgColor = 'rgba(' + d[0] + ',' + d[1] + ',' + d[2] + ',1)';
        }
      }
      if (urlObject.fullscreen !== undefined && urlObject.fullscreen === 'true') {
        setTimeout(() => this.toFullScreen(), 1000);

      }
    }


    if (localStorage.getItem('CaptionedLine')) this.setCaptionedLine(parseInt(localStorage.getItem('CaptionedLine')))
    if (localStorage.getItem('size')) this.option.size = this.size = parseInt(localStorage.getItem('size'))
    if (localStorage.getItem('fontStyle')) this.setFont('' + localStorage.getItem('fontStyle'));
    if (localStorage.getItem('mode')) this.setMode('' + localStorage.getItem('mode'));
    if (localStorage.getItem('widths')) this.setWidth(parseInt(localStorage.getItem('widths')))
    if (localStorage.getItem('color')) this.option.color = localStorage.getItem('color');
    if (localStorage.getItem('captionBG')) this.option.captionBG = localStorage.getItem('captionBG');
    if (localStorage.getItem('bgColor')) this.option.bgColor = localStorage.getItem('bgColor');

  }

  splitValue(value, index) {
    return value.substring(0, index) + ' ' + value.substring(index);
  }

  hexToR(h) {
    const a = parseInt((this.cutHex(h)).substring(0, 2), 16);
    const b = parseInt((this.cutHex(h)).substring(2, 4), 16);
    const c = parseInt((this.cutHex(h)).substring(4, 6), 16);
    return [a, b, c];
  }
  cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }

  // Viewer section //

  toIntPlus(size, change) {
    this.option.size = this.size = parseInt(size, 10) + parseInt(change, 10);
    if (this.size > 200) {
      this.option.size = this.size = 200;
    }
    localStorage.setItem('size', '' + this.option.size);

  }
  toIntMinus(size, change) {
    this.option.size = this.size = parseInt(size, 10) - parseInt(change, 10);
    if (this.size < 10) {
      this.option.size = this.size = 10;
    }
    localStorage.setItem('size', '' + this.option.size);
  }

  setFontSize(size) {
    this.option.size = size.target.value;
    localStorage.setItem('size', '' + this.option.size);
  }

  setFont(fontStyle) {
    this.FreesiaUPCActive = this.THNiramitASActive = this.AngsanaNewActive = this.CordiaNewActive = '';
    if (fontStyle === 'FreesiaUPC') { this.FreesiaUPCActive = 'active'; }
    if (fontStyle === 'THNiramitAS') { this.THNiramitASActive = 'active'; }
    if (fontStyle === 'AngsanaNew') { this.AngsanaNewActive = 'active'; }
    if (fontStyle === 'CordiaNew') { this.CordiaNewActive = 'active'; }
    this.option.fontStyle = fontStyle;
    localStorage.setItem('fontStyle', fontStyle);
  }

  setMode(mode) {
    this.textOnlyActive = this.videoActice = this.audioActice = '';
    let audio = document.getElementById('audio') as HTMLAudioElement;
    audio.muted = false;
    if (mode === 'text-only') {
      if (this.mode !== 'text-only') {
        this.settingCaptionedLine = this.settingCaptionedWidth = 'hide';
        this.temp[0] = this.classCaptionedWidth;
        this.temp[1] = this.classCaptionedLine;
        this.option.classCaptionedWidth = this.classCaptionedWidth = '';
        this.option.classCaptionedLine = this.classCaptionedLine = '';
        this.option.captioned_default = this.captioned_default = 'captioned-text-only';
        this.option.videoTextOnly = 'show-inline';
        this.option.video = 'hide';
      }
      this.mode = 'text-only';
      this.option.mode = 'text-only';
      this.textOnlyActive = 'active';
      audio.muted = true;
    }

    if (mode === 'video-text') {
      this.enableVideo()
      if (this.mode !== 'video-text') {
        if (this.mode === 'text-only') {
          this.option.classCaptionedWidth = this.classCaptionedWidth = this.temp[0];
          this.option.classCaptionedLine = this.classCaptionedLine = this.temp[1];
          this.settingCaptionedLine = this.settingCaptionedWidth = 'inline';
          this.option.videoTextOnly = 'hide';
        }
        this.option.video = 'show-inline';
        this.option.captioned_default = this.captioned_default = 'captioned-default';
      }
      this.option.mode = this.mode = 'video-text';
      this.videoActice = 'active';
    }

    if (mode === 'audio-text') {
      if (this.mode !== 'audio-text') {
        this.disableVideo();
        if (this.mode === 'text-only') {
          this.option.classCaptionedWidth = this.classCaptionedWidth = this.temp[0];
          this.option.classCaptionedLine = this.classCaptionedLine = this.temp[1];
          this.settingCaptionedLine = this.settingCaptionedWidth = 'inline';
          this.option.videoTextOnly = 'hide';
        }
        this.option.video = 'show-inline';
        this.option.captioned_default = this.captioned_default = 'captioned-default';
      }
      this.option.mode = this.mode = 'audio-text';
      this.audioActice = 'active';
    }
    localStorage.setItem('mode', '' + this.option.mode);
  }

  setWidth(CaptionedWidth) {
    this.option.widths = CaptionedWidth;
    this.widthHundredActive = this.widthNineActive = this.widthEightActive = this.widthSevenActive = '';

    if (CaptionedWidth === 100) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-100';
      this.widthHundredActive = 'active';
    } else if (CaptionedWidth === 90) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-90';
      this.widthNineActive = 'active';
    } else if (CaptionedWidth === 80) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-80';
      this.widthEightActive = 'active';
    } else if (CaptionedWidth === 70) {
      this.option.classCaptionedWidth = this.classCaptionedWidth = 'captioned-width-70';
      this.widthSevenActive = 'active';
    }

    localStorage.setItem('widths', '' + CaptionedWidth);
  }

  setCaptionedLine(CaptionedLine) {
    this.option.CaptionedLine = CaptionedLine;
    this.twoLineActive = this.threeLineActive = '';

    if (CaptionedLine === 3) {
      this.option.classCaptionedLine = this.classCaptionedLine = 'captioned-line-3';
      this.threeLineActive = 'active';
      $('#captioned').removeClass('captioned-line-2').addClass('captioned-line-3');
    } else if (CaptionedLine === 2) {
      this.option.classCaptionedLine = this.classCaptionedLine = 'captioned-line-2';
      this.twoLineActive = 'active';
      $('#captioned').removeClass('captioned-line-3').addClass('captioned-line-2');
    }
    localStorage.setItem('CaptionedLine', '' + this.option.CaptionedLine);
  }

  toggleActive() {
    if (this.option.optionStatus === 'Inactive') {
      this.option.optionStatus = 'Active';
      this.option.optionPiority = true;
    } else if (this.option.optionStatus === 'Active') {
      document.removeEventListener('click', () => { });
      this.option.optionStatus = 'Inactive';
      this.option.optionPiority = false;
      this.ngbDropdown.close();
    }
  }

  clearText() {
    this.option.clearText();
  }

  enter() {
    this.option.mouseOptionEnter = true;
  }

  leave() {
    this.option.mouseOptionEnter = false;

  }

  mouseUp() {
    if (this.ngbDropdown.isOpen()) {
      if (!this.option.mouseOptionEnter) {
        this.ngbDropdown.close();
        this.option.optionStatus = 'Inactive';
        this.option.optionPiority = false;
        this.videoCom.mouseOverVideo();

        if (!this.option.mouseEnter) {
          this.option.statusStreaming = this.option.terminateViewer = this.option.options = 'hide-smooth2';
        }
      }
    }
  }

  toFullScreen() {
    this.videoCom.toFullScreen();
    this.option.full = 'hide';
    this.option.exitFullscreen = 'show-inline';
  }

  exitFullScreen() {
    this.videoCom.exitFullScreen();
    this.full = 'show-inline';
    this.exitFullscreen = 'hide';
  }

  disableVideo() {
    $('#video').css('opacity', 0);
    this.option.visibility = 'hide';
    this.option.visibilityOff = 'show-block';
  }

  enableVideo() {
    $('#video').css('opacity', 1);
    this.option.visibility = 'show-block';
    this.option.visibilityOff = 'hide';
  }


  setColor(e) {
    localStorage.setItem('color', e);
  }

  captionBG(e) {
    localStorage.setItem('captionBG', e);
  }

  playv() {
    this.video.pause();
    this.audio.pause();
    this.video.play();
    this.audio.play();
    this.option.isPlay = true;
  }

  pausev() {
    this.video.pause();
    this.audio.pause();
    this.option.isPlay = false;
  }


}
