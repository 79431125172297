<!-- !PAGE CONTENT! -->
<div id='content' class="w3-main" [style.margin-left]="margin">
  <!-- Push down content on small screens -->
  <div class="w3-hide-large" [style.margin-top]="topMargin"></div>



  <!-- BEFORE VIEWER -->
  <section id="beforeStreaming" [ngClass]="beforeStreaming">
    <div class="w3-container">
      <div class="w3-row">
        <div class="w3-col m12">
          <button [ngClass]="broadcaster" (click)="viewerStart('video')" id="start-broadcaster-video"
            class="w3-button w3-dark-grey">เริ่มถ่ายทอดสดเสียงและวิดีโอ</button>
          <button [ngClass]="broadcaster" (click)="viewerStart('audio')" id="start-broadcaster-audioOnly"
            class="w3-button w3-indigo">เริ่มถ่ายทอดสดเสียง</button>
          <button [ngClass]="broadcaster" (click)="viewerStart('playback')" id="stream-playback"
            class="w3-button w3-red">playback</button>
          <button [ngClass]="viewer" class="w3-button w3-ripple w3-red w3-hover-black" id="startViewer"
            (click)="viewerStart('no')">รับชม</button>
        </div>
      </div>
      <hr *ngIf="!this.isBroadcast">
    </div>
  </section>

  <!-- FOR START VIEWER WEB-->
  <div *ngIf="this.start">
    <app-video [id]="this.id" [isBroadcast]="this.broadcast"></app-video>
  </div>

  <!-- Header -->
  <header class="w3-container" *ngIf="!this.isBroadcast">
    <div style="float: right">
      {{this.option.number}}
      <i class="material-icons" style="font-size: 16px" >
          visibility
        </i>
    </div>
     
    <div *ngIf="!this.start" id="title">{{this.option.title}}</div>
    
    <div *ngIf="!this.start">
      โดย {{this.option.owner}}
    </div>

    <div id="description" class="w3-container">{{this.option.description}}
      
  </div>

  </header>
<header class="w3-container" *ngIf="!this.start && this.isBroadcast">
    <div id="title">{{this.option.title}}</div>
    
    <div>
      โดย {{this.option.owner}}
    </div>

    <div id="description" class="w3-container">{{this.option.description}}
    </div>
</header>
  
  <!-- Description -->
  <div class="liveContainerBroadcast" *ngIf="this.start && this.isBroadcast" > 
    <table>
        <tr >
            <th colspan="5"><i class="material-icons">
              announcement
              </i>ข้อมูลการเชื่อมต่อ ICE</th>
        </tr>
        <tr style="background-color: #11151E ">
              <td><div id="ice" style="height: 100vh;"></div></td>
        </tr>
    </table>

    <!-- <div>
      <i class="material-icons">
        announcement
        </i>ข้อมูลการเชื่อมต่อ ICE
    </div>

    <div id="ice">

    </div> -->
    
    <!-- <button (click)="this.broadcast.stopBroadcast()">Stop</button> -->
  </div>

  <!-- print log error -->
  <br>

</div>