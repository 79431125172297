import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Connection } from './connected.service';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public currentUserSubject: BehaviorSubject<object>;
    public currentUser: Observable<any>;
    private baseurl: string;
    constructor(private http: HttpClient, private connected: Connection, private oauthService: OAuthService) {
      this.currentUserSubject = new BehaviorSubject<any>(this.getUser());
      this.currentUser = this.currentUserSubject.asObservable();
    }
    // user login
    // login(username: string, password: string) {
    //   return this.http.post(this.baseurl, {
    //     username,
    //     password
    //   });
    // }
    // user logout
    logout() {
      this.oauthService.logOut();
      localStorage.removeItem('auth');   
    }
    setUserAuth(user) {
      this.currentUserSubject.next(user);
      localStorage.setItem('auth', JSON.stringify(user));
    }
    // get user authenticate
    getUser() {
      return JSON.parse(localStorage.getItem('auth'));
    }

    login() {
      this.oauthService.initImplicitFlow();
    }
  }
