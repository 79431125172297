<nav *ngIf="!this.option.isMobile" class="w3-sidenav w3-red w3-animate-left w3-collapse w3-top w3-large " id="mySidenav"

[ngClass]="{'w3-open': this.option.show, 'w3-close':!this.option.show}">
  <div class="w3-container w3-padding-16">

    <div style="text-align: center;">
      <h3>
        <a href="index.html" style="background: no-repeat;color:white;"><img style=" width: 250px;"
          src="../../../assets/img/viewer_logo.png"></a>
      </h3>
      <div class="login">
        <button id="choice" (click)='logout()' class="w3-red"><i class="material-icons">
          supervisor_account
          </i>ออกจากระบบ</button>
      </div>
      <input (keyup)="onKeyUp($event)" id="searchRooms" class="w3-input w3-border" type="text"
        placeholder="ค้นหาห้อง...">
        
    </div>
  </div>
  <ul id="menuViewer">
    <li mat-raised-button 
    matTooltip='{{data.title}}' 
    matTooltipPosition="right" 
    matTooltipClass="tooltip-class"
    *ngFor="let data of rooms ;let i = index" 
    (click)='this.getData(data.id)' 
    class="listrooms">
      <a>
        <div class="dotdot">{{data.title}} </div>
        <font size="1">
        <b>เริ่ม</b> {{this.startDate[i]}} เวลา {{this.startTime[i]}} 
        <br>
        <b>สิ้นสุด</b> {{this.endDate[i]}} เวลา {{this.endTime[i]}}
        </font>
        <div [style.color]="statusColor[i]" style="font-size: 13px; ">
          {{this.status[i]}}
          <br *ngIf="status[i] != ''">
          {{this.timeStatus[i]}}
        </div>
      </a>
    </li>
  </ul>

  <ul *ngIf="noConnection" id="menuViewer">
    <h1>
      <b id="notFound">อุปส์!</b>
    </h1>
    <p>เกิดข้อผิดพลาดระหว่างการเชื่อมต่อ โปรดลองใหม่อีกครั้ง</p>
    <button id="reconnectRooms">ลองใหม่</button>
  </ul>
  <ul *ngIf="rooms.length == 0" id="menuViewer" class="error">
    <!-- Not found Rooms -->
    <div id="notFound">ไม่พบข้อมูล</div>
    <button id="reconnectRooms" (click)="getRoom()">ลองใหม่</button>
  </ul>


  <ul *ngIf="rooms.length == 0" id="menuViewer" class="error">
    <!-- Not found Rooms -->
    <div style="text-align: center">ไม่พบข้อมูล</div>
  </ul>
 
</nav>

<router-outlet>

</router-outlet>