<a *ngIf="!this.isMobile">

    <!-- Menu -->
    <!-- Top menu on small screens -->
    <header *ngIf="header" class="w3-container w3-top w3-hide-large w3-red w3-xlarge w3-padding" style="z-index:3;">
      <a class="w3-btn w3-red w3-margin-right" (click)="w3_open()">☰</a>
      <span>TRANSCRIPTION</span>
    </header>
  
    <!-- Overlay effect when opening sidenav on small screens -->
    <div [ngClass]="{'w3-open': this.option.show, 'w3-close':!this.option.show}" class="w3-overlay" (click)="w3_close()"
      title="close side menu">
    
    
    </div>
  
  
    <!-- Top menu -->
  
     
  

    <router-outlet>
     
    </router-outlet>
  </a>
  
  <a *ngIf="this.isMobile">
    
    <nav class="mw3-sidenav w3-red w3-animate-left w3-collapse w3-top w3-large" id="mySidenav" [ngClass]="this.sideNav"
      style="width: 100%; font-weight: bold; z-index: 3;">
      <div class="w3-container">
            <div style="text-align: center;">
                    <h4>
                      <a href="index.html" style="background: no-repeat;text-decoration: none;" class="w3-red">คำบรรยายแทนเสียงประเทศไทย <br>(Thailand Captioning Service Center)</a>
                    </h4>
                  </div>
          
        </div>
        </nav>
    <router-outlet></router-outlet>
  
  </a>
