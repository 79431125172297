import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, TimeScale, Tooltip, Title } from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, TimeScale, Tooltip, Title);

@Injectable({
  providedIn: 'root'
})
export class Connection {
  private baseUrl: string;
  private webdis: string;
  private publicUrl: string;


  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.API_URL;
    this.publicUrl = environment.API_PUBLIC_URL;
    this.webdis = environment.WEBDIS_URL;
  }

  getAllData() {
    return this.httpClient.get(`${this.publicUrl}/rooms`);
  }
  getData(id) {
    return this.httpClient.get(`${this.publicUrl}/rooms/` + id);
  }
  getDataByowner(id) {
    return this.httpClient.get(`${this.baseUrl}/rooms/owner/` + id);
  }
  searchData(title) {
    return this.httpClient.get(`${this.baseUrl}/rooms?search=title&keyword=` + title);
  }
  getFixedRoomId(username) {
    return this.httpClient.get(`${this.webdis}/GET/` + username);

  }
}

@Injectable({
  providedIn: 'root'
})
export class GetRoom {

  public presentTime;
  public rooms: any[] = [];
  public startTime: string[][] = [];
  public startDate: string[][] = [];
  public endTime: string[][] = [];
  public endDate: string[][] = [];
  public Stime; Etime;
  public status: any[] = [];
  public timeStatus: any[] = [];
  public startPeriod: any[] = [];
  public endPeriod: any[] = [];
  public searchText: string;
  public statusColor: any[] = [];
  public title; description;

  roomData(result) {
    this.rooms = [];
    this.startTime = [];
    this.startDate = [];
    this.endTime = [];
    this.endDate = [];
    this.statusColor = [];
    this.status = [];
    this.timeStatus = [];
    let index = 0;
    let id = []
    result = result.sort((a, b) => { return b.id - a.id });
    result.forEach(room => {
      if (room.status == 1) { }
      else {
        this.rooms.push(room)
        id.push("" + room.id);
        const temp1 = (room.time.start_time).split(' ');

        let datesinit = temp1[0].split('-');
        let datesfin: any = '';
        for (let i = 0; i < datesinit.length; i++) {
          datesfin += '' + datesinit[datesinit.length - (i + 1)] + '-';
        }
        this.startTime.push(temp1[1].slice(0, -3));
        this.startDate.push(datesfin.slice(0, -1));

        const temp2 = (room.time.end_time).split(' ');
        let dateeinit = temp2[0].split('-');
        let dateefin: any = '';
        for (let i = 0; i < dateeinit.length; i++) {
          dateefin += '' + dateeinit[dateeinit.length - (i + 1)] + '-';
        }
        this.endTime.push(temp2[1].slice(0, -3));
        this.endDate.push(dateefin.slice(0, -1));
        this.initializeClock(index, room.time.start_time, room.time.end_time, room.status);

        index++;
      }

    });
    let difference = this.allStorage().filter(x => !id.includes(x));
    difference.forEach(roomid => {
      localStorage.removeItem("room_id_" + roomid)
    });
  }

  allStorage() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      if (keys[i].includes('room_id')) {
        let id = keys[i].split('_')
        values.push(id[id.length - 1]);
      }
    }
    return values;
  }



  showRoom(room) {
    this.title = room.title;
    this.description = room.description;

    const temp1 = (room.time.start_time).split(' ');
    this.Stime = (temp1[1].slice(0, -3));

    const temp2 = (room.time.end_time).split(' ');
    this.Etime = (temp2[1].slice(0, -3));
    let index = 0;

    for (index = 0; index < this.rooms.length; index++) {
      if (this.rooms[index].id === room.id) {
        this.initializeClock(index, room.time.start_time, room.time.end_time, room.status);
        return index;
      }
    }
    return index;
  }


  initializeClock(index, startTime, endTime, roomStatus) {

    this.updateClock(index, startTime, endTime, roomStatus);

    setInterval(() => {
      this.updateClock(index, startTime, endTime, roomStatus);
    }, 1000 * 60
    );
  }


  updateClock(index, startTime, endTime, roomStatus) {


    const start = this.getTimeRemaining(startTime);
    //  let end = this.getTimeRemaining(endTime);

    if (roomStatus === 6 || roomStatus === 7) {
      this.status[index] = 'การถ่ายทอดสดสิ้นสุดแล้ว';
      this.timeStatus[index] = '';
      this.statusColor[index] = '#cccccc';
    } else if (roomStatus === 3) {
      this.status[index] = '';
      this.timeStatus[index] = start.hour + ' ชั่วโมง ' + start.minute + ' นาทีจะทำการถ่ายทอดสด';
      this.statusColor[index] = '#ecd31f';
      if (start.hour === 0 && start.minute < 30) {
        this.status[index] = '';
        this.timeStatus[index] = start.hour + ' ชั่วโมง ' + start.minute + ' นาทีจะทำการถ่ายทอดสด';
        this.statusColor[index] = '#ecd31f';
      } else if (start.hour < 0) {

        if (window.location.href.includes('broadcast')) {
          this.status[index] = 'พร้อมสำหรับการถ่ายทอดสด';
        } else {
          this.status[index] = 'การถ่ายทอดสดจะเริ่มในไม่ช้า';
        }

        this.timeStatus[index] = '';
        this.statusColor[index] = 'orange';
      }
    } else if (roomStatus === 4) {
      this.status[index] = 'กำลังทำการถ่ายทอดสด';
      this.timeStatus[index] = '';
      this.statusColor[index] = '#5cb85c';
    } else if (roomStatus === 5) {
      this.status[index] = 'พักการถ่ายทอดสด';
      this.timeStatus[index] = '';
      this.statusColor[index] = '#FF6666';
    } else if (roomStatus === 2) {
      this.status[index] = 'ห้องรอการอนุมัติ';
      this.timeStatus[index] = '';
      this.statusColor[index] = '#FFFF66';
    } else {
      this.status[index] = '';
      this.timeStatus[index] = start.hour + ' ชั่วโมง ' + start.minute + ' นาทีจะทำการถ่ายทอดสด';
      this.statusColor[index] = 'orange';
    }
  }
  getTimeRemaining(time) {

    this.presentTime = new Date();
    time = time.replace(/-/g, '/')
    const total = Date.parse(time) - Date.parse(this.presentTime);
    const minute = Math.floor((total / 1000 / 60) % 60);
    const hour = Math.floor((total / (1000 * 60 * 60)));

    return {
      'total': total, 'hour': hour, 'minute': minute
    };
  }

}

@Injectable()
export class optionService {

  // global variable

  isMobile = false;
  title = '';
  owner = '';
  description = '';
  data;
  id;
  status;
  startTime;
  endTime;
  atextSize = [10, 16, 18, 22, 24, 26, 28, 36, 48, 72, 84, 96, 100];
  size = 36;
  fontColorPicker; widths; fontsize; fontStyle; color; bgColor; captionBG; fontWeight;
  caption = '';
  font = 'FreesiaUPC';
  mode = 'video-text';
  CaptionedWidth = '70';
  CaptionedLine = '2';
  classCaptionedLine = 'captioned-line-2';
  classCaptionedWidth = 'captioned-width-70';
  captioned_default = 'captioned-default';
  temp: any[] = [];
  optionVideoMode; myModal; m_options; m_optionVideoMode; choice; options;
  statusStreaming; terminateViewer;
  full = 'show-inline';
  exitFullscreen = 'hide';
  visibility = 'show-block';
  visibilityOff = 'hide';
  video = 'show-inline';
  videoTextOnly = 'hide';
  fullscreenHeight = 'normal-height';
  isFullscreen = false;
  dropupContent = 'hide';
  optionStatus = 'Inactive';
  optionPiority = false;
  mouseEnter = false;
  mouseOptionEnter = false;
  show = false;
  sideNav = 'w3-open';
  prevScrollHeight = 0;
  number = 0;

  videoLineChart: any
  audioLineChart: any
  public chartLegend = true;
  public chartType = 'line';
  optionLineChart: any = {
    responsive: false,
    maintainAspectRatio: true,
    scales: {
      x: {
        min: 0,
        max: 29,
        ticks: {
          stepSize: 1
        },
      },
      y: {
        beginAtZero: true,
        min: 0,

      }
    },
    elements: {
      line: {
        tension: 0
      }
    },
  }


  videoBitrate = [];
  audioBitrate = [];
  local = {
    address: '',
    port: '',
    protocol: ''
  };
  remote = {
    address: '',
    port: '',
    protocol: ''
  };
  isPlay = true;
  isTablet;

  clearText() {
    document.getElementById('captioned').innerText = '';
    this.caption = '';
  }

  getAllUrlParams(url) {

    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    const obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      const arr = queryString.split('&');

      for (let i = 0; i < arr.length; i++) {
        // separate the keys and the values
        const a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        const paramName = a[0];
        const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          const key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) { obj[key] = []; }

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            const index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }
    return obj;
  }

  setGraph() {
    const video = document.getElementById('videoChart') as HTMLCanvasElement;
    const audio = document.getElementById('audioChart') as HTMLCanvasElement;
    this.videoLineChart = new Chart(video, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: 'Video Bitrate kbps',
          data: this.videoBitrate,
          borderWidth: 2,
          fill: false,
          borderColor: 'red',
          pointRadius: 1,
          pointHoverRadius: 1
        }],
      },
      options: this.optionLineChart
    })

    this.audioLineChart = new Chart(audio, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: 'Audio Bitrate kbps',
          data: this.audioBitrate,
          borderWidth: 2,
          fill: false,
          borderColor: 'orange',
          pointRadius: 1,
          pointHoverRadius: 1
        }],
      },

      options: this.optionLineChart
    })
  }

}
